var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kiwi-header kiwi-theme-bg",on:{"click":_vm.onHeaderClick}},[(_vm.isChannel())?[_c('div',{staticClass:"kiwi-header-name-container"},[_c('div',{staticClass:"kiwi-header-name"},[_c('div',{staticClass:"kiwi-header-name-full"},[_vm._v(_vm._s(_vm.buffer.name))]),_vm._v(_vm._s(_vm.buffer.name)+" ")])]),_c('div',{staticClass:"kiwi-header-topic",domProps:{"innerHTML":_vm._s(_vm.formattedTopic)}}),(_vm.isJoined && _vm.isConnected)?_c('div',{key:_vm.buffer.id,staticClass:"kiwi-header-options"},[_vm._l((_vm.pluginUiChannelElements),function(plugin){return _c('div',{directives:[{name:"rawElement",rawName:"v-rawElement",value:({
                    el: plugin.el,
                    props: {
                        kiwi: {
                            buffer: _vm.buffer,
                            containerheader: _vm.self,
                        }
                    }
                }),expression:"{\n                    el: plugin.el,\n                    props: {\n                        kiwi: {\n                            buffer: buffer,\n                            containerheader: self,\n                        }\n                    }\n                }"}],key:plugin.id,staticClass:"kiwi-header-option"})}),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-about",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'about'
                }},[_c('a',{attrs:{"title":_vm.$t('more_information')},on:{"click":function($event){return _vm.sidebarState.toggleAbout()}}},[_c('i',{staticClass:"fa fa-info",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-nicklist",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'nicklist'
                        || _vm.sidebarSection === 'user'
                }},[_c('a',{attrs:{"title":_vm.$t('person', {count: Object.keys(_vm.buffer.users).length})},on:{"click":function($event){return _vm.sidebarState.toggleNicklist()}}},[_c('i',{staticClass:"fa fa-users",attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v(_vm._s(Object.keys(_vm.buffer.users).length))])])]),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-settings",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'settings'
                }},[_c('a',{attrs:{"title":_vm.$t('channel_settings')},on:{"click":function($event){return _vm.sidebarState.toggleBufferSettings()}}},[_c('i',{staticClass:"fa fa-cog",attrs:{"aria-hidden":"true"}})])]),(_vm.sidebarState.isPinned)?_c('div',{staticClass:"kiwi-header-option kiwi-header-option-unpinsidebar"},[_c('a',{on:{"click":function($event){return _vm.sidebarState.unpin()}}},[_c('i',{staticClass:"fa fa-thumb-tack",attrs:{"aria-hidden":"true"}})])]):_vm._e()],2):_vm._e(),(!_vm.isJoined && _vm.isConnected)?_c('div',{staticClass:"kiwi-header-notjoined"},[_c('a',{staticClass:"u-link kiwi-header-join-channel-button",on:{"click":_vm.joinCurrentBuffer}},[_vm._v(" "+_vm._s(_vm.$t('container_join'))+" ")])]):_vm._e()]:(_vm.isServer())?[_c('div',{staticClass:"kiwi-header-name-container"},[_c('div',{staticClass:"kiwi-header-name"},[_vm._v(" "+_vm._s(_vm.buffer.getNetwork().name)+" ")])]),_c('div',{staticClass:"kiwi-header-server-connection"},[(_vm.buffer.getNetwork().state === 'disconnected')?_c('a',{staticClass:"u-button u-button-primary",on:{"click":_vm.onConnectButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('connect'))+" ")]):(_vm.buffer.getNetwork().state === 'connecting')?_c('span',[_c('i',{staticClass:"fa fa-spin fa-spinner",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t('connecting'))+" ")]):_vm._e()])]:(_vm.isQuery())?[_c('div',{staticClass:"kiwi-header-name-container"},[_c('div',{staticClass:"kiwi-header-name"},[_c('away-status-indicator',{staticClass:"kiwi-header-awaystatus",attrs:{"network":_vm.buffer.getNetwork(),"user":_vm.network.userByName(_vm.buffer.name)}}),_vm._v(" "+_vm._s(_vm.buffer.name)+" ")],1)]),_c('div',{key:_vm.buffer.id,staticClass:"kiwi-header-options"},[(_vm.userOnline)?_c('div',{staticClass:"kiwi-header-option kiwi-header-option-user",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'user'
                }},[_c('a',{on:{"click":function($event){return _vm.toggleUser()}}},[_c('i',{staticClass:"fa fa-user",attrs:{"aria-hidden":"true"}})])]):_vm._e(),_vm._l((_vm.pluginUiQueryElements),function(plugin){return _c('div',{directives:[{name:"rawElement",rawName:"v-rawElement",value:({
                    el: plugin.el,
                    props: {
                        kiwi: {
                            buffer: _vm.buffer,
                            containerheader: _vm.self,
                        }
                    }
                }),expression:"{\n                    el: plugin.el,\n                    props: {\n                        kiwi: {\n                            buffer: buffer,\n                            containerheader: self,\n                        }\n                    }\n                }"}],key:plugin.id,staticClass:"kiwi-header-option"})})],2)]:(_vm.isSpecial())?[_c('div',{staticClass:"kiwi-header-name-container"},[_c('div',{staticClass:"kiwi-header-name"},[_vm._v(_vm._s(_vm.buffer.name))])]),_c('div',{staticClass:"kiwi-header-options"})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }