var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kiwi-statebrowser kiwi-theme-bg"},[(!_vm.$state.setting('hideSettings'))?_c('div',{staticClass:"kiwi-statebrowser-appsettings",attrs:{"title":_vm.$t('kiwi_settings')},on:{"click":_vm.clickAppSettings}},[_c('i',{staticClass:"fa fa-cog",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.isPersistingState)?_c('state-browser-usermenu',{attrs:{"network":_vm.getNetwork}}):_vm._e(),_c('div',{staticClass:"kiwi-statebrowser-tools"},_vm._l((_vm.pluginUiElements),function(plugin){return _c('div',{directives:[{name:"rawElement",rawName:"v-rawElement",value:({
                el: plugin.el,
                props: {
                    kiwi: {
                        statebrowser: _vm.self,
                    }
                }
            }),expression:"{\n                el: plugin.el,\n                props: {\n                    kiwi: {\n                        statebrowser: self,\n                    }\n                }\n            }"}],key:plugin.id,staticClass:"kiwi-statebrowser-tool"})}),0),_c('div',{staticClass:"kiwi-statebrowser-scrollarea"},[_c('div',{staticClass:"kiwi-statebrowser-networks"},_vm._l((_vm.networksToShow),function(network){return _c('state-browser-network',{key:network.id,attrs:{"network":network,"sidebar-state":_vm.sidebarState,"active-prompt":_vm.activePrompt}})}),1),_c('br'),_c('div',{staticClass:"kiwi-ad-container",staticStyle:{"width":"215px","height":"400px"}},[(_vm.shouldShowAd)?_c('Adsense',{staticStyle:{"display":"block"},attrs:{"data-ad-client":"ca-pub-9106844814451489","data-ad-slot":"7202727008","data-ad-format":"auto","data-full-width-responsive":true}}):_vm._e()],1)]),(!_vm.isRestrictedServer)?_c('div',{staticClass:"kiwi-statebrowser-newnetwork"},[_c('a',{staticClass:"u-button u-button-primary",on:{"click":_vm.clickAddNetwork}},[_vm._v(" "+_vm._s(_vm.$t('add_network'))+" "),_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}})])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }